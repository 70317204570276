<template>
  <div ref="intersection-observer" class="featured-document-list">
    <div class="title">{{ title }}</div>
    <div class="description">{{ description }}</div>

    <featured-document-carousel :documents="documents" :list-id="listId" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import FeaturedDocumentCarousel from './featured-document-carousel.vue';
import { ImpressionDetector } from '@/utils/impressionUtils';
import { DocRecord } from 'wklr-backend-sdk/models';

@Component({ components: { FeaturedDocumentCarousel } })
export default class FeaturedDocumentList extends Vue {
  @Prop() title!: string;
  @Prop() description!: string;
  @Prop() documents!: DocRecord[];
  @Prop() listId!: string;

  $refs!: { 'intersection-observer': HTMLDivElement };
  impressionDetector?: ImpressionDetector;

  mounted(): void {
    // 50% の領域が 5000 ミリ秒以上表示されたらインプレッションイベントを送る
    this.impressionDetector = new ImpressionDetector(
      this.$telemetry,
      this.$route,
      this.$refs['intersection-observer'],
      0.5,
      5000,
      'featured-document-list',
      this.listId,
    );
  }

  beforeDestroy(): void {
    this.impressionDetector?.cleanup();
  }
}
</script>
<style lang="scss" scoped>
.featured-document-list {
  > .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  > .description {
    font-size: 14px;
  }
}
</style>
