<template>
  <div v-if="show" class="org-link-container">
    <a :href="destination">組織アカウント（ {{ orgDomain }} ）にアクセスする</a>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useAuth } from '@/plugins/auth';
import { GetAccountResponse } from 'wklr-backend-sdk/models';
import { useRepositories } from '@/plugins/repositories';

export default defineComponent({
  setup() {
    const $auth = useAuth();
    const $repositories = useRepositories();

    const account = ref<GetAccountResponse | null>(null);
    const orgDomain = computed(() => {
      if (!account.value) return undefined;
      const nonPersonalOrganizations = account.value.organizations.filter(
        (organization) => organization.oid !== 'personal',
      );
      return nonPersonalOrganizations.length ? `${nonPersonalOrganizations[0].subdomain}.legalscape.jp` : undefined;
    });
    const destination = computed(() => (orgDomain.value ? `https://${orgDomain.value}` : undefined));
    const isPersonal = computed(() => {
      // $auth.user.organization には「現在アクセスしている」組織が入ってくる
      // personal ドメインにアクセスしていて、組織に属している場合に、メッセージを表示する
      return $auth.user.organization.oid === 'personal';
    });
    const show = computed(() => {
      return isPersonal.value && destination.value;
    });
    onMounted(async () => {
      if (isPersonal.value) {
        account.value = await $repositories.accounts.getAccount();
      }
    });
    return { account, orgDomain, destination, show };
  },
});
</script>
<style lang="scss" scoped>
.org-link-container {
  background: #fffacd;
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
  a {
    color: #7f7a5d;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}
</style>
