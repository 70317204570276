import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { ref } from 'vue';
import ViewerLink from '@/components/viewer-link.vue';
import { useTelemetry } from '@/plugins/telemetry';
import { isAccessibleDocRecord, shouldAppendNewIcon } from '@/utils/documentUtils';
import NO_IMAGE from '@/assets/noimage.png';

/**
 * 新着書籍のカルーセルコンポーネント
 */
export default defineComponent({
  name: 'RecentlyAddedDocumentCarousel',
  components: {
    ViewerLink: ViewerLink
  },
  props: {
    records: {
      type: Array,
      required: true
    }
  },
  setup: function setup(props) {
    var route = useRoute();
    var telemetry = useTelemetry();
    var carousel = ref(null);
    var page = ref(0);
    var carouselWidth = ref(0);
    var noImage = NO_IMAGE;
    var records = ref(structuredClone(props.records));

    // 画像読み込みエラー時のハンドラ
    var handleImageError = function handleImageError(id) {
      records.value.map(function (v) {
        if (v.id === id) {
          return _objectSpread(_objectSpread({}, v), {}, {
            thumbnailURI: noImage
          });
        }
        return v;
      });
    };
    var onResize = function onResize() {
      if (carousel.value != null) {
        carouselWidth.value = carousel.value.$el.clientWidth;
      }
    };
    var pages = function pages() {
      // 左右のボタン幅それぞれ 36px を引いた幅を 1 冊あたりの幅 150px で割る。最低 1 冊は表示する
      var pageSize = Math.max(Math.floor((carouselWidth.value - 36 * 2) / 150), 1);
      var pages = [];
      for (var i = 0; i < records.value.length; i += pageSize) {
        var docs = records.value.slice(i, i + pageSize);
        pages.push(docs);
      }
      return pages;
    };
    var carouselChangeHandler = function carouselChangeHandler() {
      telemetry.sendClickTelemetry({
        button: 'recently-added-document-carousel__change'
      }, route);
    };
    var documentLinkClickHandler = function documentLinkClickHandler(id) {
      telemetry.sendClickTelemetry({
        button: 'recently-added-document-carousel__document-link',
        params: {
          featuredDocumentList: {
            id: id
          }
        }
      }, route);
    };
    var isShouldAppendNewIcon = function isShouldAppendNewIcon(record) {
      if (isAccessibleDocRecord(record)) {
        return shouldAppendNewIcon(record.collectedAt);
      }
      return false;
    };
    onMounted(function () {
      onResize();
    });
    return {
      carousel: carousel,
      page: page,
      noImage: noImage,
      handleImageError: handleImageError,
      pages: pages,
      isShouldAppendNewIcon: isShouldAppendNewIcon,
      carouselChangeHandler: carouselChangeHandler,
      documentLinkClickHandler: documentLinkClickHandler,
      onResize: onResize
    };
  }
});