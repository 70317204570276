<template>
  <div>
    <v-dialog v-model="show" persistent max-width="500px" transition="dialog-transition">
      <v-card>
        <v-card-title class="headline" primary-title> 登録フォーム </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-text-field
              v-model="formData.name"
              class="required"
              :rules="nameRules"
              label="氏名"
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.tel"
              class="required"
              :rules="telRules"
              label="電話番号"
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.email"
              class="required"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
            <v-text-field
              v-model="formData.company"
              class="required"
              :rules="companyRules"
              label="所属企業"
              required
            ></v-text-field>
            <v-text-field v-model="formData.position" label="役職"></v-text-field>
            <v-checkbox v-model="checkbox" class="centered">
              <template #label>
                <p>
                  <a target="_blank" :href="TERMS_OF_SERVICE_GENERAL" @click.stop>利用規約</a>と<a
                    target="_blank"
                    :href="PRIVACY_POLICY"
                    @click.stop
                    >プライバシーポリシー</a
                  >に同意して利用を開始する
                </p>
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!checkbox || !valid" color="primary" class="mr-4" @click="submitHandler"> 送信 </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="manual" max-width="840px" transition="dialog-transition">
      <v-card>
        <div class="readme-title">Legalscape -簡易版- でお試しいただけること</div>
        <div class="wrap-readme-img">
          <img class="readme" :src="readme" alt="はじめに" />
          <div class="locate-link">
            ※正式版に関する詳細は<a target="_blank" :href="FULLVER_INTRODUCTION_FOR_MHM_MYPAGE">こちら</a>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
/* eslint @typescript-eslint/explicit-module-boundary-types: 0 */
import { AboutLegalscape, Feedback } from '@/constants';
import { Component, Vue } from 'nuxt-property-decorator';
import MTRIAL_README from '@/assets/mtrial-readme.png';

const telRegex = /^0\d{9,10}$/;
// https://stackoverflow.com/a/201378/4956279
const emailRegex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

@Component({})
export default class TrialRegistrationDialog extends Vue {
  static readonly mhmTrialRegisteredKey = 'mtrial';
  static readonly defaultFormData = {
    name: '',
    email: '',
    tel: '',
    position: '',
    company: '',
  };

  checkbox = false;
  valid = false;
  show = false;
  manual = false;
  formData = TrialRegistrationDialog.defaultFormData;
  readonly readme = MTRIAL_README;

  nameRules: ((name: string) => string | boolean)[] = [(name) => !!name || '氏名の入力は必須です'];

  telRules: ((tel: string) => string | boolean)[] = [
    (tel) => !!tel || '電話番号の入力は必須です',
    (tel) => telRegex.test(tel) || '電話番号は0から始まる10桁もしくは11桁を半角数字(-なし)で入力してください',
  ];

  emailRules: ((email: string) => string | boolean)[] = [
    (email) => !!email || 'Emailの入力は必須です',
    (email) => emailRegex.test(email) || 'Emailアドレスの形式が正しくありません',
  ];

  companyRules: ((comapny: string) => string | boolean)[] = [(company) => !!company || '所属企業は必須です'];

  readonly PRIVACY_POLICY = AboutLegalscape.PRIVACY_POLICY;

  readonly TERMS_OF_SERVICE_GENERAL = AboutLegalscape.TERMS_OF_SERVICE_GENERAL;

  readonly FULLVER_INTRODUCTION_FOR_MHM_MYPAGE = AboutLegalscape.FULLVER_INTRODUCTION_FOR_MHM_MYPAGE;

  get requestUrl(): string {
    return Feedback.MypageTrialRegistrationSSUrl + '?' + new URLSearchParams(Object.entries(this.formData)).toString();
  }

  alreadyRegistered(): boolean {
    return !!window.localStorage.getItem(TrialRegistrationDialog.mhmTrialRegisteredKey);
  }

  submitHandler() {
    window.localStorage.setItem(TrialRegistrationDialog.mhmTrialRegisteredKey, '1');
    this.show = false;
    this.manual = true;

    // Apps Script 経由で Spread Sheet に登録情報を書き込む。
    // axios を使用すると 'no-cors' の指定が出来ないので fetch を使用する
    fetch(this.requestUrl, { mode: 'no-cors' });
  }

  mounted() {
    if (this.$domain.isMHMMypage && !this.alreadyRegistered()) {
      this.show = true;
    }
  }
}
</script>
<style lang="scss">
.form-container {
  padding: 32px;
}
.required label::after {
  content: '（必須）';
}

.centered {
  justify-content: center;
}

.readme {
  display: block;
  width: 744px;
  margin: 0 auto;
}

.readme-title {
  background: #4a5c97;
  color: #fff;
  padding: 40px 0 32px;
  font-size: 34px;
  font-weight: bold;
  text-align: center;
}

.wrap-readme-img {
  position: relative;
  padding: 32px 0;
}

.locate-link {
  position: absolute;
  left: 432px;
  top: 300px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.1em;
  z-index: 10;
}
</style>
