export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    linkPath: {
      type: String,
      required: false,
      default: undefined
    },
    linkText: {
      type: String,
      required: false,
      default: undefined
    },
    showUnavailableMessage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup: function setup(props) {
    var shouldHaveLink = props.linkPath && props.linkText;
    var unavailableMessage = '※「Legalscape -簡易版-」では利用できません';
    return {
      shouldHaveLink: shouldHaveLink,
      unavailableMessage: unavailableMessage
    };
  }
});