<template>
  <a class="header-announcement" :href="url" target="_blank">
    {{ title }}
  </a>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class HeaderAnnouncement extends Vue {
  @Prop() title!: string;
  @Prop() url!: string;
}
</script>

<style lang="scss" scoped>
.header-announcement {
  display: block;
  text-decoration: none;
  background: #757575;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 4px 16px;

  &:hover {
    text-decoration: underline;
  }
}
</style>
