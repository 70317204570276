import PortalSectionHeader from '@/components/portalSection/portal-section-header.vue';
import { useTelemetry } from '@/plugins/telemetry';
import { ImpressionDetector } from '@/utils/impressionUtils';
export default defineComponent({
  name: 'WandhSection',
  components: {
    PortalSectionHeader: PortalSectionHeader
  },
  setup: function setup() {
    var telemetry = useTelemetry();
    var route = useRoute();
    var intersectionObserver = ref();
    var impressionDetector = ref();
    onMounted(function () {
      if (!intersectionObserver.value) {
        return;
      }
      impressionDetector.value = new ImpressionDetector(telemetry, route, intersectionObserver.value,
      // 80% 以上の領域が 6000 ミリ秒以上表示されたらインプレッションイベントを送る
      0.8, 6000, 'portal-wandh-section');
    });
    onBeforeUnmount(function () {
      var _impressionDetector$v;
      (_impressionDetector$v = impressionDetector.value) === null || _impressionDetector$v === void 0 ? void 0 : _impressionDetector$v.cleanup();
    });
    var handleWandhImgClick = function handleWandhImgClick() {
      telemetry.sendClickTelemetry({
        button: 'portal-wandh-section__wandh-img'
      }, route);
    };
    var handleWandhDetailsClick = function handleWandhDetailsClick() {
      telemetry.sendClickTelemetry({
        button: 'portal-wandh-section__wandh-details'
      }, route);
    };
    var handleWandhD1TrialClick = function handleWandhD1TrialClick() {
      telemetry.sendClickTelemetry({
        button: 'portal-wandh-section__visit-d1-trial'
      }, route);
    };
    var handleWandhBtnClick = function handleWandhBtnClick() {
      telemetry.sendClickTelemetry({
        button: 'portal-wandh-section__wandh-btn'
      }, route);
    };
    return {
      handleWandhImgClick: handleWandhImgClick,
      handleWandhDetailsClick: handleWandhDetailsClick,
      handleWandhD1TrialClick: handleWandhD1TrialClick,
      handleWandhBtnClick: handleWandhBtnClick
    };
  }
});