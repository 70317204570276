<template>
  <div ref="intersection-observer" class="featured-documents-section">
    <portal-section-header icon="mdi-trending-up" title="注目書籍・雑誌リスト" />
    <featured-document-list
      v-for="list in lists"
      :key="list.id"
      :title="list.name"
      :description="list.description"
      :documents="list.documents"
      :list-id="list.id"
    />
    <div class="notice">
      <v-icon small>mdi-flask</v-icon>注目書籍リストは開発中の新機能です。期間限定でお試しいただけます。
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import FeaturedDocumentList from '@/components/featuredDocuments/featured-document-list.vue';
import PortalSectionHeader from '@/components/portalSection/portal-section-header.vue';
import { ImpressionDetector } from '@/utils/impressionUtils';
import { FeaturedDocRecord } from 'wklr-backend-sdk/models';

@Component({
  components: {
    FeaturedDocumentList,
    PortalSectionHeader,
  },
})
export default class FeaturedDocumentsSection extends Vue {
  lists: FeaturedDocRecord[] = [];

  $refs!: { 'intersection-observer': HTMLDivElement };
  impressionDetector?: ImpressionDetector;

  async mounted() {
    // 10% の領域が 5000 ミリ秒以上表示されたらインプレッションイベントを送る
    // (注目書籍リストのセクションは縦長になりうるので、表示領域の割合を大きくしすぎると物理的にインプレッションが発生しなくなってしまう)
    this.impressionDetector = new ImpressionDetector(
      this.$telemetry,
      this.$route,
      this.$refs['intersection-observer'],
      0.1,
      5000,
      'featured-documents-section',
    );
    this.lists = await this.$repositories.docs.getFeaturedDocumentsLists();
  }

  beforeDestroy(): void {
    this.impressionDetector?.cleanup();
  }
}
</script>
<style lang="scss" scoped>
.featured-documents-section {
  > .notice {
    margin-top: 16px;
    font-size: 12px;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > .v-icon {
      margin-right: 4px;
    }
  }
}
</style>
