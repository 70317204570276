<template>
  <div>
    <component
      :is="section"
      v-for="section in sections()"
      :key="section"
      class="portal-section"
      v-bind="portalSectionProps"
    >
    </component>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import WandhSection from '@/components/portalSection/wandh-section.vue';
import PracticeAreasSection from '@/components/portalSection/practice-areas-section.vue';
import MyCollectionSection from '@/components/portalSection/my-collection-section.vue';
import RecentlyAddedSection from '@/components/portalSection/recently-added-section.vue';
import RecentlyAddedPurchasableSection from '@/components/portalSection/recently-added-purchasable-section.vue';
import HistorySection from '@/components/portalSection/history-section.vue';
import FeaturedDocumentsSection from '@/components/portalSection/featured-documents-section.vue';
import FormatCategoriesSection from '@/components/portalSection/format-categories-section.vue';
import PurchasedSection from '@/components/portalSection/purchased-section.vue';

interface PortalSectionProps {
  disablePersonalFeature: boolean;
}

@Component({
  components: {
    WandhSection,
    PracticeAreasSection,
    MyCollectionSection,
    RecentlyAddedSection,
    PurchasedSection,
    HistorySection,
    FeaturedDocumentsSection,
    RecentlyAddedPurchasableSection,
    FormatCategoriesSection,
  },
})
export default class PortalSectionsList extends Vue {
  sections(): string[] {
    // 個人向け機能を無効化している場合は、マイコレクションと履歴は空っぽなので表示順を下げる
    // W&Hも表示しない
    // TODO 注目書籍リストも一旦出さない（実験終了後本格運用に入ったら閲覧ページ遷移の代わりに営業モーダルに差し替えるなどする）
    if (this.disablePersonalFeature) {
      return [
        'practice-areas-section',
        'recently-added-section',
        'format-categories-section',
        'my-collection-section',
        'history-section',
      ];
    }
    // 献本にはW&H、注目書籍リストは出さない
    if (this.$domain.isKenpon) {
      return [
        'practice-areas-section',
        'my-collection-section',
        'format-categories-section',
        'recently-added-section',
        'history-section',
      ];
    }
    // MHMマイページ、商事法務、商事法務図書館向け会員には購入済み買い切り書籍リストは出さない
    if (this.$domain.isSHKommentar || this.$domain.isSHKommentarLibrary) {
      return [
        'practice-areas-section',
        'my-collection-section',
        'format-categories-section',
        'recently-added-section',
        'featured-documents-section',
        'history-section',
      ];
    }

    if (this.$auth.user.organization.oid === 'personal') {
      return [
        'wandh-section',
        'practice-areas-section',
        'my-collection-section',
        'format-categories-section',
        'purchased-section',
        'recently-added-purchasable-section',
        'history-section',
      ];
    }

    return [
      'wandh-section',
      'practice-areas-section',
      'my-collection-section',
      'recently-added-section',
      'featured-documents-section',
      'format-categories-section',
      'purchased-section',
      'recently-added-purchasable-section',
      'history-section',
    ];
  }
  /** MHMマイページを通じて提供しているユーザーには個人向け機能を無効化する */
  get disablePersonalFeature(): boolean {
    return this.$domain.isMHMMypage;
  }
  get portalSectionProps(): PortalSectionProps {
    return {
      disablePersonalFeature: this.disablePersonalFeature,
    };
  }
}
</script>
