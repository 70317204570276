<template>
  <div>
    <portal-section-header
      title="購入済み書籍"
      icon="mdi-shopping"
      link-path="/purchased"
      link-text="購入済み書籍一覧"
    />
    <div v-if="loadingPurchased" class="text-center">
      <v-progress-circular indeterminate />
    </div>
    <div v-else-if="!purchasedDocs.length">
      <p class="noEntry">
        購入済みコンテンツがありません。
        <a :href="getStoreTopPageUrl()" target="_blank">Legalscape Store</a>
        にてコンテンツを購入すると、こちらにコンテンツが表示されるようになります。 <br />
      </p>
    </div>
    <div v-else class="frame">
      <template v-for="document in purchasedDocs">
        <document-list-item
          :key="document.id"
          :disable-document-link="disablePersonalFeature"
          :document="document"
          :disabled="true"
          variant="small"
        />
        <hr :key="document.id + '-hr'" />
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import PortalSectionHeader from '@/components/portalSection/portal-section-header.vue';
import DocumentListItem from '@/components/document-list-item.vue';
import { DocRecord } from 'wklr-backend-sdk/models';
import { getShopifyMultipassAuthUrl } from '@/utils/shopifyUtils';
@Component({
  components: {
    PortalSectionHeader,
    DocumentListItem,
  },
})
export default class PurchasedSection extends Vue {
  @Prop({ required: false, default: false }) disablePersonalFeature!: boolean;

  /** 新着書籍データをロード中 */
  loadingPurchased = true;

  /** 新着書籍リスト */
  purchasedDocs: DocRecord[] = [];

  mounted(): void {
    this.loadPurchasedDocs();
  }

  getStoreTopPageUrl(): string {
    const storeOrigin = process.env.SHOPIFY_STORE_ORIGIN;
    if (!storeOrigin) {
      return '';
    }
    return getShopifyMultipassAuthUrl(storeOrigin);
  }

  async loadPurchasedDocs(): Promise<void> {
    this.purchasedDocs = await this.$repositories.users.purchasedDocuments(3);
    this.loadingPurchased = false;
  }
}
</script>
<style lang="scss" scoped>
.frame {
  padding: 15px;
  border: 1px solid rgb(224, 224, 224);

  hr {
    margin: 0 15px;
    border: none;
    border-top: 2px solid rgb(239, 239, 239);

    &:last-child {
      display: none;
    }
  }
}
</style>
