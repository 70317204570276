import { defineComponent } from 'vue';
import { useTelemetry } from '@/plugins/telemetry';
export default defineComponent({
  setup: function setup() {
    var LP_URL_A = 'https://store.legalscape.jp/?utm_source=legalscape&utm_medium=referral&utm_campaign=top_banner_202403';
    var telemetry = useTelemetry();
    var route = useRoute();
    var targetUrl = ref(LP_URL_A);
    var handleClick = function handleClick() {
      telemetry.sendClickTelemetry({
        button: 'top-banner__store-lp'
      }, route);
    };
    return {
      handleClick: handleClick,
      targetUrl: targetUrl
    };
  }
});