import { $telemetry } from '@/plugins/telemetry';
import { Route } from 'vue-router';

type SharedIntersectionObserverCallback = (entry: IntersectionObserverEntry) => void;

/**
 * インプレッションを検知する目的で利用する IntersectionObserver インスタンスを管理します。
 *
 * パフォーマンス影響を低減するために、コンポーネントごとに個別の IntersectionObserver インスタンスを
 * 用意するのではなく、共通のインスタンスを利用するようにしています。
 *
 * なお一つの IntersectionObserver インスタンスを共用する都合上、閾値 (threshold) は 0.1 刻みで
 * 事前に指定しています。
 */
class SharedIntersectionObserver {
  private intersectionObserver: IntersectionObserver;
  private targets: Map<Element, SharedIntersectionObserverCallback> = new Map();

  constructor() {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const callback = this.targets.get(entry.target);
          if (callback !== undefined) {
            callback(entry);
          }
        });
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
      },
    );
  }

  /**
   * 指定された要素をこの IntersectionObserver の監視対象に加えます。
   *
   * @param target 監視対象として加えたい要素
   * @param callback 当該要素の領域がビューポートに入り、その交差割合が閾値 (0.1 刻み) をまたぐ度にこのコールバックが呼び出される
   */
  observe(target: Element, callback: SharedIntersectionObserverCallback): void {
    if (this.targets.has(target)) {
      console.warn('指定された要素はすでに IntersectionObserver の監視対象として登録されています');
      return;
    }

    this.intersectionObserver.observe(target);
    this.targets.set(target, callback);
  }

  /**
   * 指定された要素をこの IntersectionObserver の監視対象から外します。
   */
  unobserve(target: Element): void {
    this.intersectionObserver.unobserve(target);
    this.targets.delete(target);
  }
}

const sharedIntersectionObserver = new SharedIntersectionObserver();

/**
 * 指定されたコンポーネントが一定時間、指定された割合以上の表示領域がビューポートに含まれていた場合に
 * インプレッションイベントを送信する機能を提供します。
 *
 * インプレッションイベントは一つのコンポーネントにつき一度だけ送信されます。
 * (ユーザのスクロール操作などによってコンポーネントがビューポートに出たり入ったりを繰り返しても、
 * 二回目以降のインプレッションイベントは送信されません）
 */
export class ImpressionDetector {
  // インプレッションイベントが送信済みであれば true になる
  private isImpressionSent = false;

  // このプロパティが undefined ではない場合、待機時間経過待ちのインプレッションイベントが存在することを表す
  private timer?: number;

  /**
   * @param telemetry
   * @param route
   * @param target 監視対象のコンポーネント
   * @param ratioThreshold インプレッションを判定する条件の一つ。
   *                       この割合以上のコンポーネントの表示領域がビューポートに含まれている必要がある。
   *                       0 以上 1 以下の 0.1 刻みの数値を指定する必要がある。
   *                       0 が指定された場合は、表示割合に関わらずビューポートと交差状態にある (IntersectionObserverEntry.isIntersecting が true)
   *                       ときに表示領域の条件が満たされたと判定されるようになる。
   * @param delayMillis インプレッションを判定する条件の一つ。
   *                    上記した表示領域の条件が、ここで指定したミリ秒以上の時間保たれる必要がある。
   * @param componentType コンポーネントの種類を表す文字列
   * @param componentId componentType が同じだがコンポーネントが複数存在する場合にそれらを区別する任意の識別子
   */
  constructor(
    private readonly telemetry: $telemetry,
    private readonly route: Route,
    private readonly target: Element,
    private readonly ratioThreshold: number,
    private readonly delayMillis: number,
    private readonly componentType: string,
    private readonly componentId?: string,
  ) {
    if (this.ratioThreshold < 0 || this.ratioThreshold > 1) {
      console.warn(`threshold には 0 以上 1 以下の数値を指定する必要があります: ${this.ratioThreshold}`);
      this.ratioThreshold = 0;
    }

    sharedIntersectionObserver.observe(this.target, (entry) => {
      if (entry.isIntersecting && entry.intersectionRatio >= this.ratioThreshold) {
        this.emitImpressionWithDelay();
      } else {
        this.cancel();
      }
    });
  }

  /**
   * IntersectionObserver の監視対象を外すなどの後始末をします。
   *
   * この ImpressionDetector を利用する Vue コンポーネントの beforeDestroy() にて、
   * このメソッドを必ず呼び出すようにしてください。
   */
  cleanup(): void {
    this.cancel();
    sharedIntersectionObserver.unobserve(this.target);
  }

  /**
   * インプレッションイベントを一定時間待機した後に送信します。
   */
  private emitImpressionWithDelay(): void {
    if (this.isImpressionSent || this.timer) {
      // IntersectionObserver には 0.1 刻みの閾値を指定しているので、表示領域が 10% 変化するたびにこのメソッドが呼び出されうる。
      // したがってこのメソッドが呼び出されるたびに、待機中のインプレッションイベントの存否をチェックする必要がある。
      return;
    }

    // setTimeout() の戻り値の型が扱いづらいので、ここでは意図的に window.setTimeout() を利用している
    // cf. https://stackoverflow.com/a/55607969
    this.timer = window.setTimeout(() => this.sendImpressionEvent(), this.delayMillis);
  }

  /**
   * 待機中のインプレッションイベントの送信を取りやめます。
   */
  private cancel(): void {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  /**
   * 実際にインプレッションイベントを送信します。
   */
  private sendImpressionEvent(): Promise<void> {
    this.isImpressionSent = true;
    this.timer = undefined;

    // インプレッションが確定したので、もはや IntersectionObserver で監視する必要はない
    sharedIntersectionObserver.unobserve(this.target);

    return this.telemetry.sendImpressionTelemetry(
      {
        componentType: this.componentType,
        componentId: this.componentId,
        viewedMillis: this.delayMillis,
        viewedRatio: this.ratioThreshold,
      },
      this.route,
    );
  }
}
