<template>
  <div>
    <portal-section-header
      v-if="disablePersonalFeature"
      title="マイ・コレクション"
      icon="mdi-bookmark"
      :show-unavailable-message="true"
    />
    <portal-section-header
      v-else
      title="マイ・コレクション"
      icon="mdi-bookmark"
      link-path="/collections"
      link-text="マイ・コレクション一覧"
    />
    <h5>クイックアクセス</h5>
    <quick-access />

    <template v-if="showBinder">
      <h5>バインダー</h5>

      <div class="binder-wrapper">
        <template v-if="!disablePersonalFeature">
          <v-card
            v-for="item in recentBinders"
            :key="item.id"
            :to="`/binder/${item.id}`"
            class="pa-2 binder-card"
            :color="`-${highlightColorName(item.highlightColor)}`"
          >
            <v-icon left>mdi-clipboard-outline</v-icon>{{ item.name }}
          </v-card>
          <p v-if="recentBinders.length === 0" class="noEntry">
            何度も閲覧する文書をバインダーに登録すると、こちらから簡単にアクセスできるようになります
          </p>
        </template>
        <p v-else class="noEntry">
          何度も閲覧する文書をバインダーに登録すると、こちらから簡単にアクセスできるようになります
        </p>
      </div>
    </template>

    <template v-else-if="$domain.isKommentarTrial">
      <h5>バインダー</h5>

      <div>
        <p class="noEntry">何度も閲覧する文書をバインダーに登録すると、こちらから簡単にアクセスできるようになります</p>
        <p class="noEntry">※トライアルでは利用できません</p>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import PortalSectionHeader from '@/components/portalSection/portal-section-header.vue';
import { Binder } from 'wklr-backend-sdk/models';
import { byKeySorter } from '@/utils/arrayUtil';
import { highlightColorName } from '@/utils/binderFoldersUtils';
import QuickAccess from '@/components/quick-access.vue';

const sorterUpdatedAt = byKeySorter<Binder>('updatedAt');
const MAX_BINDER_ITEMS = 8;

@Component({
  components: {
    PortalSectionHeader,
    QuickAccess,
  },
})
export default class MyCollectionSection extends Vue {
  highlightColorName = highlightColorName;

  @Prop({ required: false, default: false }) disablePersonalFeature!: boolean;

  /** バインダー一覧 */
  binders: Binder[] = [];

  get recentBinders(): Binder[] {
    return [...this.binders].sort(sorterUpdatedAt).reverse().slice(0, MAX_BINDER_ITEMS);
  }

  get showBinder(): boolean {
    return this.$auth.permissions.binder;
  }

  async created(): Promise<void> {
    this.updateRootFolder();
  }

  /**
   * ルートフォルダを API から取得して更新する
   */
  async updateRootFolder(): Promise<void> {
    if (this.$auth.permissions.binder) {
      try {
        const response = await this.$repositories.binderFolders.getAllBinders(false);
        this.binders = response;
      } catch (error) {
        console.error(error);
        this.$toast.error('バインダー一覧の取得に失敗しました。リロードしてください');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.binder-wrapper {
  display: grid;

  grid-template-columns: 1fr 1fr;
  gap: 16px 24px;

  .binder-card {
    border-left-width: 8px;
    border-left-style: solid;

    &.-blue {
      border-left-color: $highlight-blue;
    }

    &.-red {
      border-left-color: $highlight-red;
    }

    &.-green {
      border-left-color: $highlight-green;
    }

    &.-purple {
      border-left-color: $highlight-purple;
    }
  }
}

h5 {
  margin: 24px 0 16px 0;

  font-size: 16px;
  line-height: 19px;

  color: rgba(0, 0, 0, 0.87);
}
</style>
